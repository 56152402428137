.message-policy {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .message-policy .form-label {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .message-policy .form-check-inline {
    margin-right: 20px;
  }
  
  .message-policy .form-check-inline label {
    font-weight: normal;
    color: #555;
  }
  
  .message-policy .form-check-inline input[type="radio"] {
    margin-right: 5px;
  }
  
  .message-policy .form-check-inline input[type="radio"]:checked + label {
    font-weight: bold;
    color: #333;
  }
  
  .alert-policy {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .alert-policy .form-label {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .alert-policy .form-check-inline {
    margin-right: 20px;
  }
  
  .alert-policy .form-check-inline label {
    font-weight: normal;
    color: #555;
  }
  
  .alert-policy .form-check-inline input[type="radio"] {
    margin-right: 5px;
  }
  
  .alert-policy .form-check-inline input[type="radio"]:checked + label {
    font-weight: bold;
    color: #333;
  }
  
  /* styles.css */
.terms-and-conditions .form-check-label {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions .form-check-input {
    margin-top: 5px;
  }
  
  .terms-and-conditions .form-check-input:invalid {
    outline: none;
    border-color: red;
  }
  
  .terms-and-conditions .link-button {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .terms-and-conditions .link-button:hover {
    color: #0056b3;
  }

  .registration-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  
  .registration-type-radio-row {
    justify-content: center;
  }
  
  .registration-type-radio {
    display: flex;
    align-items: center;
  }
  